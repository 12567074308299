<template>

  <div>

    <category-list-add-new
      v-if="$can('store', 'Category') && getCurrentCategories()[groupFilter]"
      :is-add-new-category-sidebar-active.sync="isAddNewCategorySidebarActive"
      :group="getCurrentCategories()[groupFilter]"
      @refetch-data="fetchCategories()"
    />

    <subcategory-list-add-new
      v-if="$can('store', 'Category') && getCurrentCategories()[groupFilter]"
      :is-add-new-sub-category-sidebar-active.sync="isAddNewSubCategorySidebarActive"
      :categories="resolveDataForVSelect()"
      :parent-id-of-new-sub-category.sync="parentIdOfNewSubCategory"
      @refetch-data="fetchCategories()"
    />

    <category-list-filters
      :group-filter.sync="groupFilter"
      :group-options="resolveDataForFilter()"
    />

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          />

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">

              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
              <b-dropdown
                v-if="$can('store', 'Category')"
                id="dropdown-1"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                text="Add"
                variant="primary"
              >
                <b-dropdown-item @click="isAddNewCategorySidebarActive = true">
                  Category
                </b-dropdown-item>
                <b-dropdown-item
                  @click="isAddNewSubCategorySidebarActive = true;
                          parentIdOfNewSubCategory = null"
                >
                  Sub Category
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table-simple
        v-if="getCurrentCategories()[groupFilter]"
        ref="refCategoryListTable"
        hover
        caption-top
        responsive
        class="rounded-bottom mb-0 mh-600"
      >
        <b-thead head-variant="light">
          <b-tr>
            <b-th>Group</b-th>
            <b-th>Category</b-th>
            <b-th>Sub Category</b-th>
            <b-th v-if="$can('store', 'Category')">
              Action
            </b-th>
          </b-tr>
        </b-thead>
        <b-tbody
          v-for="(category) in listCategory"
          :key="category.id"
        >
          <template v-if="category.subcategories && category.subcategories.length > 0">
            <b-tr
              v-for="(subcategory, subindex) in category.subcategories"
              :key="subcategory.id"
            >

              <b-th
                v-if="subindex === 0"
                :rowspan="category.subcategories.length"
              >

                <b-media vertical-align="center">
                  <template #aside>
                    <b-avatar
                      size="32"
                      :text="avatarText(getCurrentCategories()[groupFilter].name.en)"
                      :variant="groupFilter%2 === 0 ? 'primary' : 'success'"
                    />
                  </template>
                </b-media>

              </b-th>

              <b-th
                v-if="subindex === 0"
                :rowspan="category.subcategories.length"
              >

                <div class="d-flex">
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    size="sm"
                    variant="flat-primary"
                    @click="$bvModal.show('modal-edit-category-'+category.id)"
                  >
                    <feather-icon icon="EditIcon" />
                  </b-button>
                  <category-edit-button
                    :category.sync="category"
                    @refetch-data="fetchCategories()"
                  />
                  <div class="ml-1">
                    <span class="d-block">{{ category.name.en }}</span>
                    <span class="text-muted d-block">
                      {{ category.name.it }}
                    </span>
                  </div>
                </div>

              </b-th>
              <b-th>
                <div class="d-flex">
                  <sub-category-edit-button
                    :subcategory="subcategory"
                    @refetch-data="fetchCategories()"
                  />
                  <div class="ml-1">
                    <span class="d-block">{{ subcategory.name.en }}</span>
                    <span class="text-muted d-block">
                      {{ subcategory.name.it }}
                    </span>
                  </div>
                </div>
              </b-th>
              <b-td
                v-if="$can('store', 'Category') && subindex === 0"
                :rowspan="category.subcategories.length"
              >
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="outline-primary"
                  class="mr-2"
                  size="sm"
                  type="submit"
                  @click="isAddNewSubCategorySidebarActive = true;
                          parentIdOfNewSubCategory = category.id"
                >
                  Add now
                  <feather-icon icon="PlusIcon" />
                </b-button>
              </b-td>
            </b-tr>
          </template>

          <!--//////////////////////////////////////////////////
                        ELSE
                      /////////////////////////////////////////////////-->
          <b-tr v-else>

            <b-th>

              <b-media vertical-align="center">
                <template #aside>
                  <b-avatar
                    size="32"
                    :text="avatarText(getCurrentCategories()[groupFilter].name.en)"
                    :variant="groupFilter%2 === 0 ? 'primary' : 'success'"
                  />
                </template>
              </b-media>

            </b-th>

            <b-th
              rowspan="1"
            >
              <div class="d-flex">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  size="sm"
                  variant="flat-primary"
                  @click="$bvModal.show('modal-edit-category-'+category.id)"
                >
                  <feather-icon icon="EditIcon" />
                </b-button>
                <category-edit-button
                  :category.sync="category"
                  @refetch-data="fetchCategories()"
                />
                <div class="ml-1">
                  <span class="d-block">{{ category.name.en }}</span>
                  <span class="text-muted d-block">
                    {{ category.name.it }}
                  </span>
                </div>
              </div>
            </b-th>
            <b-td>
              this category has no sub categories
            </b-td>
            <b-td
              v-if="$can('store', 'Category')"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="outline-primary"
                class="mr-2"
                size="sm"
                type="submit"
                @click="isAddNewSubCategorySidebarActive = true;
                        parentIdOfNewSubCategory = category.id"
              >
                Add now
                <feather-icon icon="PlusIcon" />
              </b-button>
            </b-td>
          </b-tr>
        </b-tbody>
        <b-tfoot>
          <b-tr>
            <b-td
              colspan="7"
              variant="secondary"
              class="text-right"
            >
              Total Rows: <b>{{ getCurrentCategories()[groupFilter].categories.length }}</b>
            </b-td>
          </b-tr>
        </b-tfoot>
      </b-table-simple>
    </b-card>
  </div>
</template>

<script>
import {
  BTableSimple,
  BThead,
  BTr,
  BTh,
  BTd,
  BTbody,
  BTfoot,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BDropdown,
  BDropdownItem,
  BMedia,
  BAvatar,
  BButton,
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import { avatarText } from '@core/utils/filter'
import SubCategoryEditButton from '@/views/items/categories/categories-list/subcategories/SubCategoryEditButton.vue'
import CategoryListFilters from './CategoryListFilters.vue'
import CategoryListAddNew from './CategoryListAddNew.vue'
import SubcategoryListAddNew from './subcategories/SubcategoryListAddNew.vue'
import useCategoriesList from './useCategoriesList'
import CategoryEditButton from './CategoryEditButton.vue'

export default {
  components: {
    CategoryListFilters,

    SubCategoryEditButton,
    SubcategoryListAddNew,
    CategoryEditButton,
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTd,
    BTbody,
    BTfoot,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BDropdown,
    BDropdownItem,
    CategoryListAddNew,
    BMedia,
    BAvatar,
    BButton,
  },
  directives: {
    Ripple,
  },
  setup() {
    const isAddNewCategorySidebarActive = ref(false)
    const isAddNewSubCategorySidebarActive = ref(false)
    const parentIdOfNewSubCategory = ref(null)

    const {
      fetchCategories,
      getCurrentCategories,
      getCurrentSubCategory,

      resolveCategoryToTranslateVariant,

      resolveDataForVSelect,
      resolveDataForFilter,

      refCategoryListTable,
      searchQuery,
      refetchData,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      // Extra Filters
      groupFilter,
    } = useCategoriesList()

    return {
      // Sidebar
      isAddNewCategorySidebarActive,
      isAddNewSubCategorySidebarActive,
      parentIdOfNewSubCategory,
      fetchCategories,
      getCurrentCategories,
      getCurrentSubCategory,

      resolveCategoryToTranslateVariant,

      resolveDataForVSelect,
      resolveDataForFilter,

      refCategoryListTable,
      searchQuery,
      refetchData,

      // Filter
      avatarText,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      // Extra Filters
      groupFilter,
    }
  },
  computed: {
    listCategory() {
      return this.getCurrentCategories()[this.groupFilter].categories.filter(category => category.name.en.toLowerCase().includes(this.searchQuery.toLowerCase()) || category.name.it.toLowerCase().includes(this.searchQuery.toLowerCase()))
    },
  },
  mounted() {
    this.fetchCategories()
  },
}
</script>

<style lang="scss" scoped>
    .per-page-selector {
        width: 90px;
    }
</style>

<style lang="scss">
    @import '@core/scss/vue/libs/vue-select.scss';
</style>
