<template>
  <b-sidebar
    id="add-new-subcategory-sidebar"
    :visible="isAddNewSubCategorySidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-sub-category-sidebar-active', val)"
  >

    <template #default="{ hide }">

      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Add New Sub Category
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

          <!-- Category -->
          <validation-provider
            #default="validationContext"
            name="category"
            rules="required"
          >
            <b-form-group
              label="Category"
              label-for="category"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="subCategoryData.parent_id"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="categories"
                :reduce="val => val.value"
                :clearable="false"
                input-id="category"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Name -->
          <validation-provider
            #default="validationContext"
            name="Name EN"
            rules="required"
          >
            <b-form-group
              label="Name EN"
              label-for="name_en"
            >
              <b-form-input
                id="subcategory_name_en"
                v-model="subCategoryData.name.en"
                :state="getValidationState(validationContext)"
                trim
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="Name IT"
            rules="required"
          >
            <b-form-group
              label="Name IT"
              label-for="name_it"
            >
              <b-form-input
                id="subcategory_name_it"
                v-model="subCategoryData.name.it"
                :state="getValidationState(validationContext)"
                trim
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Add
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import countries from '@/@fake-db/data/other/countries'
import vSelect from 'vue-select'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {

  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewSubCategorySidebarActive',
    event: ['update:is-add-new-sub-category-sidebar-active', 'update:parent-id-of-new-sub-category'],
  },
  props: {
    isAddNewSubCategorySidebarActive: {
      type: Boolean,
      required: true,
    },
    categories: {
      type: Array,
      required: true,
    },
    parentIdOfNewSubCategory: {
      type: Number,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      countries,
    }
  },
  watch: {
    parentIdOfNewSubCategory(newVal) { // watch it
      // console.log('Prop changed: ', newVal, ' | was: ', oldVal)
      this.subCategoryData.parent_id = newVal
    },
  },
  setup(props, { emit }) {
    // Use toast
    const toast = useToast()

    const blankSubCategoryData = {
      parent_id: props.parentIdOfNewSubCategory,
      type: 'subcategory',
      name: {
        en: null,
        it: null,
      },
      slug: {
        en: null,
        it: null,
      },
    }

    const subCategoryData = ref(JSON.parse(JSON.stringify(blankSubCategoryData)))
    const resetcategoryData = () => {
      subCategoryData.value = JSON.parse(JSON.stringify(blankSubCategoryData))
    }

    const onSubmit = () => {
      subCategoryData.value.slug = subCategoryData.value.name
      store.dispatch('categories/addSubCategory', subCategoryData.value)
        .then(() => {
          emit('refetch-data')
          emit('update:is-add-new-sub-category-sidebar-active', false)
          emit('update:parent-id-of-new-sub-category', null)
        })
        .catch(error => {
          console.log(error.response)
          toast({
            component: ToastificationContent,
            props: {
              title: error.response.data.message,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetcategoryData)

    return {
      subCategoryData,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
    @import '@core/scss/vue/libs/vue-select.scss';

    #add-new-user-sidebar {
        .vs__dropdown-menu {
            max-height: 200px !important;
        }
    }
</style>
