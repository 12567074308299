<template>
  <b-sidebar
    id="add-new-category-sidebar"
    :visible="isAddNewCategorySidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-category-sidebar-active', val)"
  >

    <template #default="{ hide }">

      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Add New Category in {{ group.name.en }}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <!-- Name -->
          <validation-provider
            #default="validationContext"
            name="Name EN"
            rules="required"
          >
            <b-form-group
              label="Name EN"
              label-for="name_en"
            >
              <b-form-input
                id="name_en"
                v-model="categoryData.name.en"
                :state="getValidationState(validationContext)"
                trim
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="Name IT"
            rules="required"
          >
            <b-form-group
              label="Name IT"
              label-for="name_it"
            >
              <b-form-input
                id="name_it"
                v-model="categoryData.name.it"
                :state="getValidationState(validationContext)"
                trim
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <div class="divider mt-3">
            <div class="divider-text">
              Pricing
            </div>
          </div>

          <validation-provider
            #default="validationContext"
            name="Price"
            rules=""
          >
            <b-form-group
              label="Price"
              label-for="price"
            >
              <b-input-group
                prepend="‎€"
                class="input-group-merge"
              >
                <b-form-input
                  id="price"
                  v-model="categoryData.price"
                  :state="getValidationState(validationContext)"
                  placeholder="100"
                  trim
                />
              </b-input-group>
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="Gems Price"
            rules=""
          >
            <b-form-group
              label="Gems Price"
              label-for="gems_price"
            >
              <b-input-group
                prepend="‎€"
                class="input-group-merge"
              >
                <b-form-input
                  id="gems_price"
                  v-model="categoryData.gems_price"
                  :state="getValidationState(validationContext)"
                  placeholder="100"
                  trim
                />
              </b-input-group>
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <div class="divider mt-3">
            <div class="divider-text">
              Use with caution
            </div>
          </div>

          <validation-provider
            #default="validationContext"
            name="Custom Code"
            rules="min:3"
          >
            <b-form-group
              label="Custom Code"
              label-for="custom_code"
            >
              <b-input-group>
                <b-form-input
                  id="custom_code"
                  v-model="categoryData.custom_code"
                  :state="getValidationState(validationContext)"
                  trim
                />
              </b-input-group>
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Add
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BInputGroup,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import {
  required, alphaNum, email, integer, digits,
} from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import countries from '@/@fake-db/data/other/countries'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {

  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BInputGroup,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewCategorySidebarActive',
    event: 'update:is-add-new-category-sidebar-active',
  },
  props: {
    isAddNewCategorySidebarActive: {
      type: Boolean,
      required: true,
    },
    group: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      countries,
      integer,
      digits,
    }
  },
  setup(props, { emit }) {
    // Use toast
    const toast = useToast()

    const blankCategoryData = {
      parent_id: null,
      type: 'category',
      name: {
        en: null,
        it: null,
      },
      slug: {
        en: null,
        it: null,
      },
      price: null,
      gems_price: null,
      custom_code: null,
    }

    const categoryData = ref(JSON.parse(JSON.stringify(blankCategoryData)))
    const resetcategoryData = () => {
      categoryData.value = JSON.parse(JSON.stringify(blankCategoryData))
    }

    const onSubmit = () => {
      if (categoryData.value.custom_code === null) {
        categoryData.value.custom_code = categoryData.value.name.it.substring(0, 3)
      }
      categoryData.value.parent_id = props.group.id
      categoryData.value.slug = categoryData.value.name
      store.dispatch('categories/addCategory', categoryData.value)
        .then(() => {
          emit('refetch-data')
          emit('update:is-add-new-category-sidebar-active', false)
        }).catch(error => {
          console.log(error.response)
          Object.values(error.response.data)
            .forEach(message => {
              toast({
                component: ToastificationContent,
                props: {
                  title: message[0],
                  icon: 'AlertTriangleIcon',
                  variant: 'warning',
                },
              })
            })
        })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetcategoryData)

    return {
      categoryData,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
