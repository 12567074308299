<template>

  <b-modal
    :id="'modal-edit-category-'+category.id"
    :ref="'modal-edit-category-'+category.id"
    :title="'Editing ' + category.name.en"
    ok-title="Save"
    cancel-variant="outline-secondary"
    @show="resetModal"
    @hidden="resetModal"
    @ok="validationForm"
  >

    <validation-observer
      v-if="categoryData"
      #default="{ handleSubmit }"
      ref="refCategoryEdit"
    >

      <b-form
        class="py-1"
        @submit.prevent="handleSubmit(onSubmit)"
      >
        <!-- Name -->
        <validation-provider
          #default="validationContext"
          name="Name EN"
          rules="required"
        >
          <b-form-group
            label="Name EN"
            label-for="name_en"
          >
            <b-form-input
              :id="'name_en'+category.id"
              v-model="categoryData.name.en"
              :state="getValidationState(validationContext)"
              trim
            />
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <validation-provider
          #default="validationContext"
          name="Name IT"
          rules="required"
        >
          <b-form-group
            label="Name IT"
            label-for="name_it"
          >
            <b-form-input
              :id="'name_it'+category.id"
              v-model="categoryData.name.it"
              :state="getValidationState(validationContext)"
              trim
            />
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <div class="divider mt-1">
          <div class="divider-text">
            Pricing
          </div>
        </div>

        <validation-provider
          #default="validationContext"
          name="Price"
          rules=""
        >
          <b-form-group
            label="Price"
            label-for="price"
          >
            <b-input-group
              prepend="€"
              class="input-group-merge"
            >
              <b-form-input
                :id="'price'+category.id"
                v-model="categoryData.price"
                placeholder="100"
                :state="getValidationState(validationContext)"
                trim
              />
            </b-input-group>
            <b-form-invalid-feedback :state="getValidationState(validationContext)">
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <validation-provider
          #default="validationContext"
          name="Gems Price"
          rules=""
        >
          <b-form-group
            label="Gems Price"
            label-for="gems_price"
          >
            <b-input-group
              prepend="€"
              class="input-group-merge"
            >
              <b-form-input
                :id="'gems_price'+category.id"
                v-model="categoryData.gems_price"
                placeholder="100"
                :state="getValidationState(validationContext)"
                trim
              />
            </b-input-group>
            <b-form-invalid-feedback :state="getValidationState(validationContext)">
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <div class="divider mt-3">
          <div class="divider-text">
            Use with caution
          </div>
        </div>

        <validation-provider
          #default="validationContext"
          name="Custom Code"
          rules="min:3"
        >
          <b-form-group
            label="Custom Code"
            label-for="custom_code"
          >
            <b-input-group
              v-b-tooltip.hover.left="'Changing this code will change the code of all linked products'"
            >
              <b-form-input
                id="custom_code"
                v-model="categoryData.custom_code"
                :state="getValidationState(validationContext)"
                trim
                @input="categoryData.custom_code = categoryData.custom_code.toUpperCase()"
              />
            </b-input-group>
            <b-form-invalid-feedback :state="getValidationState(validationContext)">
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

      </b-form>
    </validation-observer>

    <template v-if="category.related_items === 0">
      <div class="divider my-1">
        <div class="divider-text">
          Only for delete
        </div>
      </div>
      <a
        class="btn text-danger"
        @click="swalConfirmDelete(category.id)"
      >
        Delete
      </a>
    </template>

  </b-modal>
</template>

<script>
import {
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BInputGroup,
  BModal,
  VBTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'CategoryEditButton',
  components: {
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BInputGroup,
    BModal,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    category: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
    }
  },
  setup(props, { emit }) {
    // Use toast
    const toast = useToast()

    const blankCategoryData = {
      id: props.category.id,
      parent_id: props.category.parent_id,
      type: 'category',
      name: {
        en: props.category.name.en,
        it: props.category.name.it,
      },
      slug: {
        en: props.category.slug.en,
        it: props.category.slug.it,
      },
      price: props.category.price,
      gems_price: props.category.gems_price,
      custom_code: props.category.custom_code,
    }

    const categoryData = ref(JSON.parse(JSON.stringify(blankCategoryData)))
    const resetcategoryData = () => {
      categoryData.value = JSON.parse(JSON.stringify(blankCategoryData))
    }

    const onSubmit = () => {
      if (categoryData.value.custom_code === null || categoryData.value.custom_code === '') {
        categoryData.value.custom_code = categoryData.value.name.it.substring(0, 3).toUpperCase()
      }
      categoryData.value.slug = categoryData.value.name
      store.dispatch('categories/updateCategory', categoryData.value)
        .then(() => {
          emit('refetch-data')
          toast({
            component: ToastificationContent,
            props: {
              title: 'Category edit successfully',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          console.log(error.response)
          Object.values(error.response.data)
            .forEach(message => {
              toast({
                component: ToastificationContent,
                props: {
                  title: message[0],
                  icon: 'AlertTriangleIcon',
                  variant: 'warning',
                },
              })
            })
        })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetcategoryData)

    return {
      resetcategoryData,
      blankCategoryData,
      categoryData,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
  methods: {
    swalConfirmDelete(id) {
      this.$swal({
        title: 'Are you sure?',
        text: 'It will delete all Sub Categories of this category',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            store.dispatch('categories/forceDeleteCategory', id)
              .then(() => {
                this.$emit('refetch-data')
                this.$swal({
                  icon: 'success',
                  title: 'Deleted!',
                  text: 'Category has been deleted.',
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
              })
          } else if (result.dismiss === 'cancel') {
            this.$swal({
              title: 'Cancelled',
              text: 'Category and his sub Categories are safe :)',
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          }
          return result.value
        })
    },
    validationForm(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.$refs.refCategoryEdit.validate().then(success => {
        if (success) {
          this.handleSubmit()
        }
      })
    },
    resetModal() {
      this.categoryData = this.blankCategoryData
    },
    handleSubmit() {
      this.onSubmit()

      // Hide the modal manually
      this.$nextTick(() => {
        this.$refs[`modal-edit-category-${this.categoryData.id}`].toggle('#toggle-btn')
      })
    },
  },
}
</script>

<style scoped>

</style>
