<template>
  <!-- form -->
  <b-dropdown
    v-if="subcategory"
    :id="'edit-sub-category-'+subcategory.id"
    ref="subCategoryEditButton"
    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
    right
    size="sm"
    variant="flat-primary"
    menu-class="w-300"
    class="dropdown-icon-wrapper"
  >
    <template #button-content>
      <feather-icon icon="EditIcon" />
    </template>

    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <b-dropdown-form
        class="py-1"
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <!-- Name -->
        <validation-provider
          #default="validationContext"
          name="Name EN"
          rules="required"
        >
          <b-form-group
            label="Name EN"
            label-for="name_en"
          >
            <b-form-input
              :id="'name_en'+subcategory.id"
              v-model="subCategoryData.name.en"
              :state="getValidationState(validationContext)"
              trim
            />
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <validation-provider
          #default="validationContext"
          name="Name IT"
          rules="required"
        >
          <b-form-group
            label="Name IT"
            label-for="name_it"
          >
            <b-form-input
              :id="'name_it'+subcategory.id"
              v-model="subCategoryData.name.it"
              :state="getValidationState(validationContext)"
              trim
            />
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <!-- Form Actions -->
        <div class="d-flex mt-2">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-2"
            type="submit"
          >
            Save
          </b-button>

        </div>
      </b-dropdown-form>
    </validation-observer>

    <template v-if="subcategory.related_items === 0">
      <b-dropdown-divider />
      <a
        class="btn text-danger"
        @click="swalConfirmDelete(subcategory.id)"
      >
        Delete
      </a>
    </template>

  </b-dropdown>
</template>

<script>
import {
  BButton,
  BDropdown,
  BDropdownForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BDropdownDivider,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import store from '@/store'
import countries from '@/@fake-db/data/other/countries'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'SubCategoryEditButton',
  components: {
    BButton,
    BDropdown,
    BDropdownForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BDropdownDivider,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    subcategory: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      countries,
    }
  },
  setup(props, { emit, refs }) {
    // Use toast
    const toast = useToast()

    const blankSubCategoryData = {
      id: props.subcategory.id,
      parent_id: props.subcategory.parent_id,
      type: 'subcategory',
      name: {
        en: props.subcategory.name.en,
        it: props.subcategory.name.it,
      },
      slug: {
        en: props.subcategory.slug.en,
        it: props.subcategory.slug.it,
      },
    }

    const subCategoryData = ref(JSON.parse(JSON.stringify(blankSubCategoryData)))
    const resetcategoryData = () => {
      subCategoryData.value = JSON.parse(JSON.stringify(blankSubCategoryData))
    }

    const onSubmit = () => {
      subCategoryData.value.slug = subCategoryData.value.name
      store.dispatch('categories/updateCategory', subCategoryData.value)
        .then(() => {
          emit('refetch-data')
          refs.subCategoryEditButton.hide(true)
        })
        .catch(error => {
          console.log(error.response)
          Object.values(error.response.data)
            .forEach(message => {
              toast({
                component: ToastificationContent,
                props: {
                  title: message[0],
                  icon: 'AlertTriangleIcon',
                  variant: 'warning',
                },
              })
            })
        })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetcategoryData)

    return {
      subCategoryData,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
  methods: {
    swalConfirmDelete(id) {
      this.$swal({
        title: 'Are you sure?',
        text: 'You won\'t be able to revert this!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            store.dispatch('categories/forceDeleteCategory', id)
              .then(() => {
                this.$emit('refetch-data')
                this.$swal({
                  icon: 'success',
                  title: 'Deleted!',
                  text: 'Sub category has been deleted.',
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
              }).catch(error => {
                console.log(error.response)
                this.$swal({
                  icon: 'error',
                  title: 'Non erasable!',
                  text: error.response.data.message,
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
              })
          } else if (result.dismiss === 'cancel') {
            this.$swal({
              title: 'Cancelled',
              text: 'Sub Category are safe :)',
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          }
          return result.value
        })
    },
  },
}
</script>

<style scoped>

</style>
